






























































import coreApiClient from "@/services/apis/coreApiClient";
import Vue from "vue";
import BarChart from './BarChart.vue';

export default Vue.extend({
    components: { BarChart },
    data() {
        return {
            tabType: "total", // "month" or "total
            tab: 0,
            chargingLocations: [],
            chargingLocationsSelected: [],
            retailers: [],
            retailersSelected: [],
            chargerVendors: [],
            chargerVendorsSelected: [],
            itemsType: ["stationsStatus"],
            width: 1000,
            loaded: false,
            chartData: null,
            rules: [
                value => {
                    if (value) { return true }

                    return 'Required.'
                },
            ],
        };
    },
    methods: {
        changeType(type: string) {
            this.tabType = type
            this.loadData()
        },
        changeTab(tab: number) {
            this.tab = tab
            this.loadData()
        },
        async loadData() {
            this.loaded = false
            try {
                const data = await coreApiClient.call("chargingStations", "chargingStationStatusStatistics", {
                    chargingLocationIds: this.chargingLocationsSelected.join("|"),
                    retailerIds: this.retailersSelected.join("|"),
                    chargerVendorIds: this.chargerVendorsSelected.join("|"),
                });
                const dataShow = {
                    month: 1,
                }
                data.forEach((item) => {
                    dataShow[item._id] = item.count
                })
                this.chartData = [dataShow]

                this.loaded = true
            } catch (e) {
                console.error(e)
            }
            this.loaded = true
        },
        async getRetailer() {
            try {
                const data = await coreApiClient.call("retailers", "findAll", {});
                this.retailers = data.items
            } catch (e) {
                console.error(e)
            }
        },
        async getChargingLocations() {
            try {
                const data = await coreApiClient.call("chargingLocations", "findAll", {});
                this.chargingLocations  = data.items
            } catch (e) {
                console.error(e)
            }
        },
        async getChargerVendors() {
            try {
                const data = await coreApiClient.call("chargerVendors", "findAll", {});
                this.chargerVendors = data.items
            } catch (e) {
                console.error(e)
            }
        },
        async getDataSearch() {
            await Promise.all([
                this.getRetailer(),
                this.getChargingLocations(),
                this.getChargerVendors(),
            ])
        },
    },
    watch: {

    },
    async mounted() {
        await this.getDataSearch();
        await this.loadData();
    },
    created() {
    },
    destroyed() {
    },
});


