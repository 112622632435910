<template>
    <div>
        <Bar v-if="chartData && tabType == 'month'" :chart-options="chartOptions" :chart-data="chartData"
             :width="width"/>
        <Pie v-if="chartData && tabType == 'total'" :chart-options="chartOptions" :chart-data="chartData"
             :width="width"/>
    </div>
</template>

<script>
import {Bar, Pie} from 'vue-chartjs/legacy'
import moment from 'moment'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    ArcElement
} from 'chart.js'


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

const isEmptyArray = (array) => {
    if (!array) {
        return true;
    }
    if (Array.isArray(array) && array.length > 0) {
        return false;
    }
    return true;
}

// const isEmptyString = (str) => {
//     return str ? str.length === 0 : true;
// }


const typeToTile = (type) => {
    switch (type) {
        case 'consumption':
            return 'Consumption (kW.h)'
        case 'usage':
            return 'Usage (hours)'
        case 'inactivity':
            return 'Inactivity (hours)'
        case 'transaction':
            return 'Number of Sessions'
        case 'amount':
            return 'Amount (VND)'
        case 'stationsstatus':
            return 'Number of Stations'
        default:
            return 'Consumption (kW.h)'
    }
}

const typeToUnit = (type) => {
    switch (type) {
        case 'consumption':
            return 'kW.h'
        case 'usage':
            return 'hours'
        case 'inactivity':
            return 'hours'
        case 'transaction':
        case 'stationsstatus':
            return ''
        case 'amount':
            return 'VND'
        default:
            return '(kW.h)'
    }
}

const buildStackedChartDataForMonths = (
    statisticsData,
    roundingDecimals = 0,
    addUnitToLabel = false,
    sortedBy = 'size-desc',
    maxNumberOfItems = 20) => {
    const totalLabel = 'Total';
    const monthLabel = 'month';
    const unitLabel = 'unit';
    const stackedChartData = {labels: [], datasets: []};
    let roundingFactor = 1;
    let monthString = '';
    let dataSetIndex = 0;
    let monthIndex = 0;
    let countMonths = 0;
    let newKey = '';
    let numberArray = [];
    let sum = 0;
    const totalDataArray = [];
    const transactionValues = statisticsData;
    const datasets = [];
    if (roundingDecimals !== 0) {
        if (roundingDecimals > 0) {
            for (let i = 0; i < roundingDecimals; i++) {
                roundingFactor *= 10;
            }
        } else {
            for (let i = roundingDecimals; i < 0; i++) {
                roundingFactor /= 10;
            }
        }
    }
    if (!isEmptyArray(transactionValues)) {
        const labels = [];
        // eslint-disable-next-line complexity
        transactionValues.forEach((transactionValue) => {
            // for each month (sorted from 0 to 11, but attention, multiple month values are possible if multiple units!):
            let totalValuePerMonth = 0;
            let newMonth = false;
            monthIndex = transactionValue.month;            
            monthString = moment().month(monthIndex).format('MMMM');
            const currentIndex = labels ? labels.indexOf(monthString) : -1;
            if (currentIndex < 0 && labels) {
                countMonths++;
                newMonth = true;
                labels.push(monthString);
            }
            labels.sort((a, b) => {
                const monthNow = moment().month();
                const monthA = moment().month(a).format('M');
                const monthB = moment().month(b).format('M');
                const monthAValue = monthNow < monthA ? monthA - 12 : monthA;
                const monthBValue = monthNow < monthB ? monthB - 12 : monthB;
                return monthAValue - monthBValue;
            });
            // now for all items:
            for (const key in transactionValue) {
                if ((key !== monthLabel) &&
                    (key !== unitLabel)) {
                    // Round
                    transactionValue[key] *= roundingFactor;
                    transactionValue[key] = Math.round(transactionValue[key]);
                    transactionValue[key] /= roundingFactor;
                    if (transactionValue[key] && transactionValue[key] !== 0) {
                        if (addUnitToLabel && (unitLabel in transactionValue)) {
                            newKey = key + ` [${transactionValue[unitLabel]}]`;
                        } else {
                            newKey = key;
                        }
                        dataSetIndex = datasets.findIndex((dataset) => dataset.label === newKey);
                        if (dataSetIndex < 0 && datasets) {
                            numberArray = [];
                            for (let i = 1; i < countMonths; i++) {
                                // add leading zeros for previous months without activity
                                numberArray.push(0);
                            }
                            numberArray.push(transactionValue[key]);
                            datasets.push({label: newKey, data: numberArray, stack: 'item'});
                        } else {
                            numberArray = datasets[dataSetIndex].data;
                            if (newMonth) {
                                numberArray.push(transactionValue[key]);
                            } else {
                                let monthlyNumber = numberArray[countMonths - 1];
                                if (typeof (monthlyNumber) === 'number') {
                                    monthlyNumber += transactionValue[key];
                                    numberArray[countMonths - 1] = monthlyNumber;
                                }
                            }
                            datasets[dataSetIndex].data = numberArray;
                        }
                        totalValuePerMonth += transactionValue[key];
                    }
                }
            }
            // add trailing zero if no activity in the current month:
            datasets.forEach((dataset) => {
                if (dataset.stack !== 'total') {
                    numberArray = dataset.data;
                    if (numberArray.length < countMonths) {
                        for (let i = numberArray.length; i < countMonths; i++) {
                            numberArray.push(0);
                        }
                        dataset.data = numberArray;
                    }
                }
            });
            // Deferred update for totals:
            if (newMonth) {
                totalDataArray.push(totalValuePerMonth);
            } else {
                let totalNumber = totalDataArray[currentIndex];
                if (typeof (totalNumber) === 'number') {
                    totalNumber += totalValuePerMonth;
                    totalDataArray[currentIndex] = totalNumber;
                }
            }
        });
        stackedChartData.labels = labels;
        // sort datasets:
        if (sortedBy.startsWith('size-')) {
            // add totals at index position 0, to be used for sorting:
            datasets.forEach((dataset) => {
                numberArray = dataset.data;
                sum = 0;
                numberArray.forEach((numberItem) => {
                    if (typeof (numberItem) === 'number') {
                        sum += numberItem;
                    }
                });
                numberArray.unshift(sum);
                dataset.data = numberArray;
            });
        }
        datasets.sort((dataset1, dataset2) => {
            switch (sortedBy) {
                case 'label-asc':
                    if (dataset1.label < dataset2.label) {
                        return -1;
                    } else {
                        if (dataset1.label > dataset2.label) {
                            return 1;
                        } else {
                            return 0;
                        }
                    }
                case 'label-desc':
                    if (dataset1.label > dataset2.label) {
                        return -1;
                    } else {
                        if (dataset1.label < dataset2.label) {
                            return 1;
                        } else {
                            return 0;
                        }
                    }
                case 'size-asc':
                    if (dataset1.data[0] < dataset2.data[0]) {
                        return -1;
                    } else {
                        if (dataset1.data[0] > dataset2.data[0]) {
                            return 1;
                        } else {
                            return 0;
                        }
                    }
                case 'size-desc':
                    if (dataset1.data[0] > dataset2.data[0]) {
                        return -1;
                    } else {
                        if (dataset1.data[0] < dataset2.data[0]) {
                            return 1;
                        } else {
                            return 0;
                        }
                    }
            }
        });
        if (sortedBy.startsWith('size-')) {
            // remove calculated totals again:
            datasets.forEach((dataset) => {
                dataset.data.splice(0, 1);
            });
        }
        if (maxNumberOfItems > 0 && datasets.length > maxNumberOfItems) {
            // push everything into the last dataset:
            const lastValidIndex = maxNumberOfItems - 1;
            let dataItem;
            datasets[lastValidIndex].label = ('statistics.others');
            for (let i = datasets.length - 1; i > lastValidIndex; i--) {
                numberArray = datasets[i].data;
                numberArray.forEach((numberItem, index) => {
                    dataItem = datasets[lastValidIndex].data[index];
                    if (typeof (dataItem) === 'number' && typeof (numberItem) === 'number') {
                        dataItem += numberItem;
                        datasets[lastValidIndex].data[index] = dataItem;
                    }
                });
                datasets.pop();
            }
        }
        // Last chart dataset for totals:
        datasets.push({label: totalLabel, data: totalDataArray, stack: 'total'});
    }
    stackedChartData.datasets = datasets;
    return stackedChartData;
}

// const calculateTotalValueFromChartData = (chartData) => {
//     let totalValue = 0;
//     let dataSetIndex = 0;
//     let numberArray = [];
//     if (chartData.datasets) {
//         // is the chart a stacked chart (with totals)?
//         dataSetIndex = chartData.datasets.findIndex((dataset) => dataset.stack === 'total');
//         if (dataSetIndex < 0) {
//             // no, it is a simple chart
//             if (!isEmptyArray(chartData.datasets)) {
//                 numberArray = chartData.datasets[0].data;
//                 if (Array.isArray(numberArray)) {
//                     numberArray.forEach((numberValue) => {
//                         if (typeof (numberValue) === 'number') {
//                             totalValue += numberValue;
//                         }
//                     });
//                 }
//             }
//         } else {
//             // yes, it is a stacked chart with totals
//             numberArray = chartData.datasets[dataSetIndex].data;
//             if (Array.isArray(numberArray)) {
//                 numberArray.forEach((numberValue) => {
//                     if (typeof (numberValue) === 'number') {
//                         totalValue += numberValue;
//                     }
//                 });
//             }
//         }
//     }
//     return totalValue;
// }

const calculateTotalChartDataFromStackedChartData = (stackedChartData) => {
    const totalChartData = {labels: [], datasets: []};
    let totalValue = 0;
    let numberArray = [];
    if (stackedChartData.datasets && totalChartData.labels) {
        stackedChartData.datasets.forEach((dataset) => {
            if (dataset.stack !== 'total') {
                const labels = totalChartData.labels;
                const datasets = totalChartData.datasets;
                labels.push(dataset.label);
                totalValue = 0;
                numberArray = dataset.data;
                if (Array.isArray(numberArray)) {
                    numberArray.forEach((numberValue) => {
                        if (typeof (numberValue) === 'number') {
                            totalValue += numberValue;
                        }
                    });
                }
                if (isEmptyArray(datasets)) {
                    numberArray = [];
                    numberArray.push(totalValue);
                    datasets.push({data: numberArray}); // no 'label, no 'stack'
                } else {
                    numberArray = datasets[0].data;
                    numberArray.push(totalValue);
                    datasets[0].data = numberArray;
                }
                totalChartData.labels = labels;
                totalChartData.datasets = datasets;
            }
        });
    }
    return totalChartData;
}

// const countNumberOfChartItems = (chartData) => {
//     let count = 0;
//     if (Array.isArray(chartData.datasets)) {
//         if (chartData.datasets.length === 1) {
//             count = chartData.datasets[0].data.length;
//         } else {
//             chartData.datasets.forEach((dataset) => {
//                 if (dataset.stack !== 'total') {
//                     count++;
//                 }
//             });
//         }
//     }
//     return count;
// }

// const calculateTotalsWithUnits = (statisticsData, roundingDecimals = 0, ignoreEmptyUnit = true) => {
//     const monthLabel = 'month';
//     const unitLabel = 'unit';
//     let roundingFactor = 1;
//     let index = 0;
//     let localString;
//     let localNumber;
//     let unitFound = false;
//     let lastUnit;
//     let totalOfLastUnit = 0;
//     let totalWithUnit;
//     const totalsWithUnit = [];
//     const transactionValues = statisticsData.result;
//     if (roundingDecimals !== 0) {
//         if (roundingDecimals > 0) {
//             for (let i = 0; i < roundingDecimals; i++) {
//                 roundingFactor *= 10;
//             }
//         } else {
//             for (let i = roundingDecimals; i < 0; i++) {
//                 roundingFactor /= 10;
//             }
//         }
//     }
//     if (!isEmptyArray(transactionValues)) {
//         transactionValues.forEach((transactionValue) => {

//             totalWithUnit = { value: 0, unit: '' };
//             unitFound = false;
//             for (const key in transactionValue) {
//                 if (key === unitLabel) {
//                     localString = transactionValue[key];
//                     if (typeof (localString) === 'string') {
//                         unitFound = true;
//                         totalWithUnit.unit = localString;
//                         if (totalWithUnit.unit === lastUnit) {
//                             totalWithUnit.value += totalOfLastUnit;
//                         } else if (totalOfLastUnit && totalOfLastUnit !== 0) {
//                             index = totalsWithUnit.findIndex((record) => record.unit === lastUnit);
//                             if (index < 0) {
//                                 totalsWithUnit.push({ value: totalOfLastUnit, unit: lastUnit });
//                             } else {
//                                 totalsWithUnit[index].value += totalOfLastUnit;
//                             }
//                             totalOfLastUnit = 0;
//                         }
//                     }
//                 } else if (key !== monthLabel) {
//                     localNumber = transactionValue[key];
//                     if (typeof (localNumber) === 'number') {
//                         // Round
//                         localNumber *= roundingFactor;
//                         localNumber = Math.round(localNumber);
//                         localNumber /= roundingFactor;
//                         totalWithUnit.value += localNumber;
//                     }
//                 }
//             }
//             if (!unitFound) {
//                 if (totalWithUnit.unit === lastUnit) {
//                     totalWithUnit.value += totalOfLastUnit;
//                 } else if (totalOfLastUnit && totalOfLastUnit !== 0) {
//                     index = totalsWithUnit.findIndex((record) => record.unit === lastUnit);
//                     if (index < 0) {
//                         totalsWithUnit.push({ value: totalOfLastUnit, unit: lastUnit });
//                     } else {
//                         totalsWithUnit[index].value += totalOfLastUnit;
//                     }
//                     totalOfLastUnit = 0;
//                 }
//             }
//             lastUnit = totalWithUnit.unit;
//             totalOfLastUnit = totalWithUnit.value;
//         });
//         // Save the last unit
//         index = totalsWithUnit.findIndex((record) => record.unit === lastUnit);
//         if (index < 0) {
//             totalsWithUnit.push({ value: totalOfLastUnit, unit: lastUnit });
//         } else {
//             totalsWithUnit[index].value += totalOfLastUnit;
//         }
//     } else {
//         totalsWithUnit.push({ value: 0, unit: '' });
//     }
//     if (ignoreEmptyUnit && totalsWithUnit.length === 2) {
//         index = totalsWithUnit.findIndex((record) => isEmptyString(record.unit));
//         if (index > -1) {
//             totalOfLastUnit = totalsWithUnit[index].value;
//             totalsWithUnit.splice(index, 1);
//             totalsWithUnit[0].value += totalOfLastUnit;
//         }
//     }
//     return totalsWithUnit;
// }


export default {
    name: 'BarChart',
    components: {
        Bar, Pie
    },
    props: ["dataBar", "width", "type", "tabType", "positionLegend"],
    data() {
        return {
            chartData: null,
            chartOptions: {
                "animation": {
                    "duration": 2000,
                    "easing": "easeOutBounce"
                },
                "plugins": {
                    "datalabels": {
                        "color": "rgba(0, 0, 0, 0.87)",
                        "font": {
                            "family": "Roboto, \"Helvetica Neue\", sans-serif"
                        }
                    },
                    "legend": {
                        "labels": {
                            "color": "rgba(0, 0, 0, 0.87)",
                            "font": {
                                "family": "Roboto, \"Helvetica Neue\", sans-serif"
                            }
                        }
                    },
                    "title": {
                        "color": "rgba(0, 0, 0, 0.87)",
                        "font": {
                            "family": "Roboto, \"Helvetica Neue\", sans-serif",
                            "size": 16
                        },
                        "text": "Charging Stations Consumption per Month (Overall: 8,231 kW.h)"
                    },
                    "tooltip": {
                        "backgroundColor": "rgba(0, 0, 0, 0.87)",
                        "bodyColor": "#fff",
                        "bodyFont": {
                            "family": "Roboto, \"Helvetica Neue\", sans-serif"
                        },
                        "callbacks": {},
                        "enabled": true,
                        "footerColor": "#fff",
                        "footerFont": {
                            "family": "Roboto, \"Helvetica Neue\", sans-serif"
                        },
                        "position": "average",
                        "titleColor": "#fff",
                        "titleFont": {
                            "family": "Roboto, \"Helvetica Neue\", sans-serif"
                        }
                    }
                },
                "scales": {
                    "x": {
                        "axis": "x",
                        "beginAtZero": false,
                        "bounds": "ticks",
                        "display": true,
                        "grace": 0,
                        "grid": {
                            "borderColor": "rgba(0,0,0,0.1)",
                            "borderDash": [],
                            "borderDashOffset": 0,
                            "borderWidth": 1,
                            "color": "rgba(0,0,0,0.1)",
                            "display": true,
                            "drawBorder": true,
                            "drawOnChartArea": true,
                            "drawTicks": true,
                            "lineWidth": 1,
                            "offset": true,
                            "tickLength": 8
                        },
                        "id": "x",
                        "offset": true,
                        "position": "bottom",
                        "reverse": false,
                        "stacked": true,
                        "ticks": {
                            "align": "center",
                            "autoSkip": true,
                            "autoSkipPadding": 3,
                            "backdropColor": "rgba(255, 255, 255, 0.75)",
                            "backdropPadding": 2,
                            "color": "rgba(0, 0, 0, 0.87)",
                            "crossAlign": "near",
                            "display": true,
                            "font": {
                                "family": "Roboto, \"Helvetica Neue\", sans-serif"
                            },
                            "labelOffset": 0,
                            "major": {},
                            "maxRotation": 50,
                            "minor": {},
                            "minRotation": 0,
                            "mirror": false,
                            "padding": 3,
                            "showLabelBackdrop": false,
                            "textStrokeColor": "",
                            "textStrokeWidth": 0
                        },
                        "title": {
                            "color": "rgba(0, 0, 0, 0.87)",
                            "display": true,
                            "font": {
                                "family": "Roboto, \"Helvetica Neue\", sans-serif"
                            },
                            "padding": {
                                "bottom": 4,
                                "top": 4
                            },
                            "text": "Months"
                        },
                        "type": "category"
                    },
                    "y": {
                        "axis": "y",
                        "beginAtZero": true,
                        "bounds": "ticks",
                        "display": true,
                        "grace": 0,
                        "grid": {
                            "borderColor": "rgba(0,0,0,0.1)",
                            "borderDash": [],
                            "borderDashOffset": 0,
                            "borderWidth": 1,
                            "color": "rgba(0,0,0,0.1)",
                            "display": true,
                            "drawBorder": true,
                            "drawOnChartArea": true,
                            "drawTicks": true,
                            "lineWidth": 1,
                            "offset": false,
                            "tickLength": 8
                        },
                        "id": "y",
                        "offset": false,
                        "position": "left",
                        "reverse": false,
                        "stacked": true,
                        "ticks": {
                            "align": "center",
                            "autoSkip": true,
                            "autoSkipPadding": 3,
                            "backdropColor": "rgba(255, 255, 255, 0.75)",
                            "backdropPadding": 2,
                            "color": "rgba(0, 0, 0, 0.87)",
                            "crossAlign": "near",
                            "display": true,
                            "font": {
                                "family": "Roboto, \"Helvetica Neue\", sans-serif"
                            },
                            "labelOffset": 0,
                            "major": {},
                            "maxRotation": 50,
                            "minor": {},
                            "minRotation": 0,
                            "mirror": false,
                            "padding": 3,
                            "showLabelBackdrop": false,
                            "textStrokeColor": "",
                            "textStrokeWidth": 0
                        },
                        "title": {
                            "color": "rgba(0, 0, 0, 0.87)",
                            "display": true,
                            "font": {
                                "family": "Roboto, \"Helvetica Neue\", sans-serif"
                            },
                            "padding": {
                                "bottom": 4,
                                "top": 4
                            },
                            "text": "text"
                        },
                        "type": "linear"
                    }
                }
            }
        }
    },
    created() {
        const titleType = typeToTile((this.type || '').toLowerCase())
        const barChartData = buildStackedChartDataForMonths(this.dataBar, 2);
        if (this.tabType === 'month') {

            this.chartOptions.scales.y.title.text = titleType

            this.chartData = barChartData
            this.updateChartOptions(barChartData, '', titleType);
            this.updateChartData(barChartData);
        } else {
            this.createPieChartOptions(typeToUnit((this.type || '').toLowerCase()));
            const pieChartData = calculateTotalChartDataFromStackedChartData(barChartData);
            this.updateChartOptions(pieChartData, '', titleType);
            this.updateChartData(pieChartData);
        }

    },
    mounted() {
    },
    updated() {
    },
    methods: {
        createPieChartOptions(toolTipUnit) {
            const mainLabel = 'Total';
            const withLegend = true;
            this.chartOptions = {
                plugins: {}
            };
            this.chartOptions.plugins.title = {
                display: true,
                text: mainLabel,
                font: {
                    weight: 'bold',
                },
            };
            this.chartOptions.plugins.legend = {
                display: withLegend,
                labels: {},
                position: 'bottom',
            };
            this.chartOptions.plugins = {};
            this.chartOptions.plugins.datalabels = {
                display: (context) => context.dataset.data[context.dataIndex] > 0,
            };
            this.chartOptions.animation = {
                duration: 2000,
                easing: 'easeOutBounce',
            };
            this.chartOptions.plugins.tooltip = {
                enabled: true,
                callbacks: {
                    label: (context) => {
                        let value = context.dataset.data[context.dataIndex];
                        let toolTip;

                        if (this.roundedChartLabels &&
                            typeof (value) === 'number') {
                            value = Math.round(value);
                        }

                        toolTip = `${context.label} : ${value}`;

                        if (toolTipUnit) {
                            toolTip = toolTip + ` ${toolTipUnit}`;
                        }
                        return toolTip;
                    },
                },
            };
        },
        updateChartData(chartData) {
            let countData = 0;
            this.chartData = chartData;
            if (!this.chartData.labels) {
                this.chartData.labels = [];
            }
            if (!this.chartData.datasets) {
                this.chartData.datasets = [];
            }
            if (this.tabType === 'month') {
                chartData.datasets.forEach((dataset) => {
                    if (dataset.stack === 'total') {
                        dataset.hidden = false;
                        dataset.backgroundColor = 'lightgrey';
                        dataset.borderWidth = 0;
                    } else {
                        dataset.stack = 'item'; // to be sure
                        dataset.hidden = false;
                        dataset.backgroundColor = this.getColorCode(countData);
                        countData++;
                        dataset.borderWidth = 0;
                    }
                });
            } else {
                chartData.datasets.forEach((dataset) => {
                    dataset.hidden = false;
                    const backgroundColor = [];
                    for (let i = 0; i < dataset.data.length; i++) {
                        backgroundColor.push(this.getColorCode(i));
                    }
                    dataset.backgroundColor = backgroundColor;
                    dataset.borderWidth = 0;
                });
            }

        },
        getColorCode(counter) {
            const colors = [
                [144, 238, 144, 0.8],
                [255, 165, 0, 0.5],
                [135, 206, 235, 0.8],
                [222, 184, 135, 0.5],
                [255, 182, 193, 0.8],
                [102, 205, 170, 0.5],
                [255, 160, 122, 0.8],
                [70, 130, 180, 0.5],
                [255, 222, 173, 0.8],
                [218, 112, 214, 0.5],
                [144, 238, 144, 0.5],
                [255, 165, 0, 0.8],
                [135, 206, 235, 0.5],
                [222, 184, 135, 0.8],
                [255, 182, 193, 0.5],
                [102, 205, 170, 0.8],
                [255, 160, 122, 0.5],
                [70, 130, 180, 0.8],
                [255, 222, 173, 0.5],
                [218, 112, 214, 0.8],
            ];
            const div20 = counter % 20;
            return `rgba(${colors[div20][0]}, ${colors[div20][1]}, ${colors[div20][2]}, ${colors[div20][3]})`;
        },
        updateChartOptions(chartData, mainLabel) {
            let minValue = 0;
            let minDivisor;
            if (!this.chartOptions.plugins.title) {
                this.chartOptions.plugins.title = {};
            }
            if (this.positionLegend) {
                this.chartOptions.plugins.legend = {
                    display: true,
                    labels: {
                        boxHeight: 30,
                        boxWidth: 50,
                        padding: 40,
                    },
                    position: this.positionLegend,
                    align: "center",
                    fullSize: true,
                };
            }
            if (mainLabel) {
                this.chartOptions.plugins.title.text = mainLabel;
            }
            this.chartOptions.plugins.title.color = this.fontColor;
            if (!this.chartOptions.plugins.title.font) {
                this.chartOptions.plugins.title.font = {};
            }
            this.chartOptions.plugins.title.font['family'] = this.fontFamily;
            this.chartOptions.plugins.title.font['size'] = this.fontSizeNumber;
            if (this.withLegend) {
                if (!this.chartOptions.plugins.legend) {
                    this.chartOptions.plugins.legend = {
                        labels: {
                            color: '',
                            font: {
                                family: '',
                            }
                        }
                    };
                }
                this.chartOptions.plugins.legend.labels.color = this.fontColor;
                this.chartOptions.plugins.legend.labels.font['family'] = this.fontFamily;
            }

            if (this.tabType === 'total') {
                minDivisor = this.constMinDivisorPie;
            } else {
                minDivisor = this.constMinDivisorBar;
                const x = this.chartOptions.scales.x;
                x['title']['color'] = this.fontColor;
                x['title']['font'] = {family: this.fontFamily};
                x.ticks.color = this.fontColor;
                x.ticks.font = {family: this.fontFamily};
                const y = this.chartOptions.scales.y;
                y['title']['color'] = this.fontColor;
                y['title']['font'] = {family: this.fontFamily};
                y.ticks.color = this.fontColor;
                y.ticks.font = {family: this.fontFamily};
            }
            this.chartOptions.plugins.tooltip.backgroundColor = this.fontColor;
            this.chartOptions.plugins.tooltip.bodyFont = {family: this.fontFamily};
            this.chartOptions.plugins.tooltip.footerFont = {family: this.fontFamily};
            this.chartOptions.plugins.tooltip.titleFont = {family: this.fontFamily};
            this.chartOptions.plugins.tooltip.bodyColor = this.inversedFontColor;
            this.chartOptions.plugins.tooltip.footerColor = this.inversedFontColor;
            this.chartOptions.plugins.tooltip.titleColor = this.inversedFontColor;
            if (this.stackedChart) {
                chartData.datasets.forEach((dataset) => {
                    if (Array.isArray(dataset.data) === true &&
                        dataset.stack === 'total') {
                        for (const data of dataset.data) {
                            if (typeof (data) === 'number' && data > minValue) {
                                minValue = data;
                            }
                        }
                    }
                });
                minValue = minValue / minDivisor;
            } else {
                chartData.datasets.forEach((dataset) => {
                    if (Array.isArray(dataset.data) === true) {
                        for (const data of dataset.data) {
                            if (typeof (data) === 'number') {
                                minValue = minValue + data;
                            }
                        }
                    }
                });
                minValue = minValue / minDivisor;
            }
            this.chartOptions.plugins.datalabels = {
                color: this.fontColor,
                font: this.font,
                display: (context) => context.dataset.data[context.dataIndex] > minValue,
                formatter: (value) => {
                    if (this.roundedChartLabels) {
                        return Math.round(value);
                    } else {
                        return value;
                    }
                },
            };
        }
    }
}
</script>
<style lang="css">
.width-100 {
    width: 100%;
}
</style>